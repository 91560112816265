import React, { useEffect, useState } from 'react';
import Header from "./components/Header";
import Footer from "./components/Footer";
import Sidebar from "./components/Sidebar";
import MainContent from "./components/MainContent";
import { Link, useLocation } from 'react-router-dom';
import dashboardService from '../apis/dashboardService'; 
import './login.css'; 
import Charts from './components/Charts';
import Status from './components/Status';
import Status1 from './components/Status1';
import LastTwoWeeks from './components/LastTwoWeeks';
import Inspections from './components/Inspections';
import logo from '../assets/logo1.png'; // Adjust the path as per the actual location
import Users from './components/Users';
const moment = require('moment-timezone');

interface Load {
  start: string;
  End: string;
  minutes: string;
  isManual: number; 
  notes: string;
  job: string,
  customerName: string
  phase: string,
  ticketNumber: string;
  orderDescription: string;
  productDescription: string;
  printDateTime: string;
  sortNumber: number;
}

interface DataItem {
  name: string;
  inspectionIn: number;
  inspectionOut: number | null;
  inspectionDate: Date;
  preTripDate: Date;
  truckId: string;
  loads: Load[];
}
function Dashboard() {

  const getMountainTimeDate = () => {
    const now = new Date(); // Gets the current date and time in the user's time zone
    const mountainTimeOffset = -6; // Mountain Standard Time (MST) offset from UTC
    const utc = now.getTime() + (now.getTimezoneOffset() * 60000); // Convert to UTC by adding the offset

    // Adjust for Mountain Time Zone
    const mst = new Date(utc + (mountainTimeOffset * 3600 * 1000));

    // Adjust for Daylight Saving Time (Mountain Daylight Time - MDT)
    const isDst = (() => {
        const jan = new Date(mst.getFullYear(), 0, 1);
        const jul = new Date(mst.getFullYear(), 6, 1);
        return mst.getTimezoneOffset() < Math.max(jan.getTimezoneOffset(), jul.getTimezoneOffset());
    })();

    // If currently observing Daylight Saving Time, add an hour to account for Mountain Daylight Time (MDT)
    if (isDst) {
        mst.setHours(mst.getHours() + 1);
    }

    return mst;
  };

  const location = useLocation();
  const [activePath, setActivePath] = useState('Today');
  const [items, setItems] = useState<DataItem[]>([]);
  const [type, setType] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [lastFetchTime, setLastFetchTime] = useState<Date | null>(null);
  const [transDate, setTransDate] = useState(getMountainTimeDate());
  function Header() {
    return (
      <div className="header1">
         <img src="/logo1.png" alt="Logo" width={20} height={30}/>
        <header className='header'>
        <span id="headerDate"></span> {/* Dedicated span for the date */}
        </header>
      </div>
    );
  }
  
  useEffect(() => {
    const headerElement = document.querySelector('.header');
    if (headerElement) {
        // Convert the transDate to a Date object and format it
        const date = new Date(transDate);
        const dateString = date.toISOString().split('T')[0];

        // Get the day of the week as a string
        const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        const dayOfWeek = daysOfWeek[date.getDay()];

        // Set the header text to include both the date and the day of the week
        headerElement.textContent = `${dateString} (${dayOfWeek})`;

       
    }

   
}, [transDate]);

  useEffect(() => {
    setActivePath(location.pathname); 
    const fetchData = async () => {
      setIsLoading(true);
      setError(null);
      try {
       
        const response = await dashboardService.getData({ type, transDate, truckId:"" });
        const rawData = response; // Adjust according to how you access the API response
       
        if(rawData.isValid==false){
          setItems([]);
        }
        
        setLastFetchTime(new Date());
        const groupedData = rawData.reduce((acc: any, curr: any) => {
          const FullName = curr.FullName!=null ? curr.FullName.trim(): '';
            if (!acc[FullName]) {
          
              acc[FullName] = {
                name: FullName,
                inspectionIn: curr.PreTripDuration, 
                inspectionOut: curr.PostTripDuration,
                inspectionDate:curr.LatestPostTripDate,
                preTripDate:curr.LatestPreTripDate,

                truckId: curr.TruckID,
                loads: []
              };
            }
            acc[FullName].loads.push({
              start: curr.TicketDate,
              End: curr.DeliveredDateTime || curr.TicketDate, 
              minutes: curr.totalMinutes, 
              isManual: curr.isManual,
              notes: curr.Note || '' , 
              job: curr.Job || '' ,
              customerName: curr.CustomerName || '',
              phase: curr.Phase,
              ticketNumber: curr.TicketNumber,
              orderDescription: curr.OrderDescription,
              productDescription: curr.ProductDescription,
              //printDateTime: curr.PrintDateTime,
              printDateTime: moment(curr.PrintDateTime).tz('America/New_York').format(),
            });
         // }
          return acc;
        }, {});
  
        Object.keys(groupedData).forEach(FullName => {
          groupedData[FullName].loads.sort((a:any, b:any) => a.sortNumber - b.sortNumber);
      });
      
        setItems(Object.values(groupedData)); // Convert the grouped object to an array
        setIsLoading(false);
      } catch (error) {

        console.error("Failed to fetch data:", error);
        setError('Failed to load data. Please try again later.');
        setIsLoading(false);
      }
    };
  
    fetchData();
  
    setActivePath("Today");


   const intervalId = setInterval(fetchData, 10 * 60 * 1000); // 5 minutes in milliseconds
  return () => clearInterval(intervalId);
  }, [location, type,transDate]); 

 
  const getTimeAgo = (fetchTime: any) => {
    if (!fetchTime) return 'Never fetched';
  
    const now = new Date();
    const diffInSeconds = Math.round((now.getTime() - fetchTime.getTime()) / 1000);
    const diffInMinutes = Math.round(diffInSeconds / 60);
  
    if (diffInMinutes === 0) return 'Just now';
    if (diffInMinutes === 1) return '1 minute ago';
    return `${diffInMinutes} minutes ago`;
  };
  
  const handleMenuClick = (event: React.MouseEvent<HTMLAnchorElement>, path: string) => {
    event.preventDefault();
    setActivePath(path);

    if(path!="Today"){

      const headerElement = document.querySelector('.header');
      if (headerElement) {
      headerElement.textContent="";
      }
    }
    
  };
  
 // Function to handle 'next' click - adds 1 day
 const handleNext = () => {
  setTransDate((prevDate) => {
    const nextDay = new Date(prevDate);
    nextDay.setDate(nextDay.getDate() + 1);
    return nextDay;
  });

};

// Function to handle 'prev' click - subtracts 1 day
const handlePrev = () => {
  setTransDate((prevDate) => {
    const prevDay = new Date(prevDate);
    prevDay.setDate(prevDay.getDate() - 1);
    return prevDay;
  });
};

    return (
      <div className="div1">
        <div>
        <img src="/logo1.png" alt="Logo" style={{ position:'absolute',top:0,left:5 }} height='60px'/>
      <div className="header" >
    
      </div>
          <div style={{marginLeft: "80%", display: (activePath === "Today") ? "block" : "none"}}>
              <button onClick={handlePrev}>Prev</button>
              <button onClick={handleNext}>Next</button>
          </div>

     
    </div>
  <div className="content-area">
  <div className="sidebar">
  <ul className="menu">
    <li className={activePath === 'Today' ? 'active' : ''}>
      <a href="#Today" onClick={(e) => handleMenuClick(e, 'Today')}>Daily Time Card</a>
    </li>
    <li className={activePath === 'Yesterday' ? 'active' : ''} >
      <a href="#Yesterday" onClick={(e) => handleMenuClick(e, 'Yesterday')}>Time Card Approval</a>
    </li>
    <li className={activePath === 'LastTwoWeeks' ? 'active' : ''}>
      <a href="#LastTwoWeeks" onClick={(e) => handleMenuClick(e, 'LastTwoWeeks')}>Pay Period</a>
    </li>
    <li className={activePath === 'PreTrip' ? 'active' : ''}>
      <a href="#PreTrip" onClick={(e) => handleMenuClick(e, 'PreTrip')}>PreTrip/PostTrip</a>
    </li>
    <li className={activePath === 'Users' ? 'active' : ''}>
      <a href="#Users" onClick={(e) => handleMenuClick(e, 'Users')}>Users</a>
    </li>

  </ul>
</div>
    <div className="main-content">
    <p>Last fetched: {getTimeAgo(lastFetchTime)}</p>
    {activePath=='Today'?
      <MainContent data={items}/>:null
    }
    {activePath=='Yesterday'?
       <Status1 data={[]} /> :null
    }
    {activePath=='Charts'?
      <Charts data={[]} /> :null
    }
    {activePath=='ApprovedRejected'?
      <Status data={[]} /> :null
    }
    {activePath=='LastTwoWeeks'?
      <LastTwoWeeks data={[]} /> :null
    }
    {activePath=='PreTrip'?
      <Inspections /> :null
    }
    {activePath=='Users'?<Users data={[]} /> :null}


  </div>
  </div>
  <div className="footer"></div>
    </div>
    );
}

export default Dashboard;
