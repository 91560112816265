import React, { useState,useEffect } from 'react';
import './compstyle.css'; 
import dashboardService from '../../apis/dashboardService'; 
import moment from 'moment-timezone';

interface TimeDifferenceProps {
  preTripDate: string;
  postTripDate: string;
}


interface Load {
  start: string;
  End: string;
  minutes: string;
  isManual: number;
  notes: string;
  job: string;
  customerName: string
  phase: string,
  orderDescription: string;
  productDescription: string;
  printDateTime: string;
}

interface TicketData {
  TicketKey: number;
  TruckID: string;
  TruckKey: number;
  DriverName: string;
  Phase: string;
  TicketDate: string;
  Operator: string;
  Note: string;
  GrossWeight: string;
  TareWeight: string;
  PrintDateTime: string;
  DeliveredDateTime: string | null;
  MaterialAmount: string | null;
  MPTicketNumber: string | null;
  TicketNumber: number;
  NetWeight: string;
  Job: string;
  JobCompanyCode: string;
  ManualWeight: number;
  CostType: string;
  Version: number;
  OrderKey: number;
  OrderDescription: string;
  CustomerName: string;
  Address1: string;
  CustomerID: string;
  OrderProductCostType: string;
  OrderProductDescription: string;
  ProductID: string;
  ProductCategory: string;
  ProductKey: number;
  ProductDescription: string;
  isManual: number;
  notes: string;
  dateRef: string;
  FullName: string;
}



interface DriverItem {
  minutes: string;
  dateRef: string;
  isApproved: string;
}

interface DataItem {
  name: string;
  inspectionIn: number;
  inspectionOut: number | null;
  inspectionDate: Date;
  preTripDate: Date;
  truckId: string;
  loads: Load[];
}

interface MainContentProps {
  data: DataItem[];
}

function MainContent({ data }: MainContentProps) {

  function formatDate1(dateString: Date) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const hours = date.getHours().toString().padStart(2, '0');
  
    return `${year}-${month}-${day} ${hours}:00`;
  }

  const getMountainTimeDate = () => {
    const now = new Date(); // Gets the current date and time in the user's time zone
    const utc = Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), now.getUTCHours(), now.getUTCMinutes(), now.getUTCSeconds());
    const mountainTimeOffset = -6; // Mountain Daylight Time (MDT) offset from UTC
    return new Date(utc + mountainTimeOffset * 3600 * 1000); // Adjusts the date object by the offset
  
  };

  
  const [allData, setAllData] = React.useState(data); // Assuming originalData is your full dataset
  const [filteredData, setFilteredData] = React.useState(data); // This will be used to render your table
  
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [drawerContent, setDrawerContent] = useState<DataItem | null>(null);
  const [drawerContent1, setDrawerContent1] = useState<Load | null>(null);
  const [contentType, setContentType] = useState<Number>(-1)
  const [type, setType] = useState('');
  const [day, setDay] = useState('');
  const [transDate, setTransDate] = useState(getMountainTimeDate());
  const [driverItems, setDriverItems] = useState<DriverItem[]>([]);
  const [detailsItems, setDetailsItems] = useState<TicketData[]>([]);
  const [showTime, setShowTime] = useState<boolean>(false);
  const [showManualLoads, setShowManualLoads] = useState<boolean>(false);
  
  useEffect(() => {
    // Assuming you only want to run this when contentType is set to 1
    if (contentType === 1) {
      setDrawerContent(null);
      setDrawerVisible(true);
    }else if(contentType==0){
      setDrawerVisible(true);
    }else{
      setDrawerVisible(false);
    }
  }, [contentType]); // This effect depends on `contentType`

  const handleCheckboxChange = () => {
    setShowTime(!showTime); // Toggle the state
  };

  const handleIsManualLoads = () => {
    const shouldShowManual = !showManualLoads;
    setShowManualLoads(shouldShowManual);
  };
  
  
// function adjustTime(dateTimeString:string) {
//     // Parse the date-time string assuming it's in a standard format like "YYYY-MM-DDTHH:MM:SS"
//     let date = new Date(dateTimeString);

//     // Subtract 6 hours
//     date.setHours(date.getHours() - 6);

//     // Extract only the HH:mm part
//     let adjustedTime = date.toISOString().substring(11, 16);

//     return adjustedTime;
// }

function adjustTime(dateTimeString: string) {
  // Check if dateTimeString is null or empty
  if (!dateTimeString) {
      return "";
  }

  //const estTime = moment.tz(dateTimeString, 'Etc/GMT').tz('America/New_York').format('HH:mm');
// The time is in EDT, add 4 hours to convert to GMT
const gmtTime = moment(dateTimeString).add(-4, 'hours').format('HH:mm');

return gmtTime;
   
// Interpret the given dateTimeString as GMT
//const easternTime = moment.tz(dateTimeString, "Etc/GMT").tz('America/New_York').format('HH:mm');

//return easternTime;
 //const estTime = moment.tz(dateTimeString, "GMT").tz('America/New_York').format('HH:mm');

//  return estTime;
}

  const handelApprov = async () => {
   
    const response = await dashboardService.dayApprove({ type, day });
    const rawData = response; 
    if(rawData.isValid==true){
    alert("Approved. data updated!");
    } else{
    alert("Something went wrong please try again;")
    }
  };

  const handelReject = async () => {
   
    const response = await dashboardService.dayRejected({ type, day });
    const rawData = response; 
    if(rawData.isValid==true){
    alert("Rejected. data updated!");
    } else{
    alert("Something went wrong please try again;")
    }
  };

  // const handleNameClick = async (item: DataItem) => {
  //   setContentType(-1);
  //   setContentType(0);
  //   setType(item.truckId);
  //   const response = await dashboardService.driverData({ type, transDate });
  //   const rawData = response;
  //   setDriverItems(rawData);
  //   setDrawerContent(item);
  //   handledayClick()
  // };

  function formatEST(dateTimeString:any) {
    if (!dateTimeString) {
        return "";
    }

    return moment(dateTimeString).tz('America/New_York').format('HH:mm');
}

function formatDurationInEST(totalMinutes:any) {
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    return `${hours}:${minutes.toString().padStart(2, '0')}`;
}


  async function handleNameClick(item:any) {
    setContentType(-1);
    setContentType(0);
    setType(item.truckId);
    
    // Assuming `transDate` needs to be in EST
    const estDate = moment(transDate).tz('America/New_York').toDate();
    
    const response = await dashboardService.driverData({ type, estDate });
    const rawData = response;
    setDriverItems(rawData);
    setDrawerContent(item);
    handledayClick();
}

  const handleCellClick = async (item: Load, index: number) => {
    setContentType(-1);
    setContentType(1)
    setDrawerContent1(item)
    setDrawerContent(null);

    handledayClick()
  };


  const handledayClick = async () => {
   
    var sDate= formatDate1(transDate);
   sDate= sDate.substring(0,10);
    setDay(sDate.substring(0,10));
   
    const response = await dashboardService.driverDetails({ type, sDate });
    const rawData = response; // Adjust according to how you access the API response
    setDetailsItems(rawData);
  };

  const closeDrawer = () => {

    setContentType(-1);
  }
  const handleNoteClick = (notes: string[]) => {
    alert(notes.filter(note => note).join("\n"));
  };

  const totalNames = data.length;

  
  function calculateTotalMinutes(data: any[]): number {
    return data.reduce((acc: number, item: any) => {
        const inspectionInMinutes: number =Number(item.inspectionIn);
        const inspectionOutMinutes: number = Number(item.inspectionOut);

        const totalMinutesFromLoads: number = item.loads.reduce((loadAcc: number, load: any, index: number) => {
            const parts: string[] = load.minutes.split(':');
            const hours: number = parseInt(parts[0], 10);
            const minutes: number = parseInt(parts[1], 10);
            let loadTotalMinutes: number = hours * 60 + minutes;

            if (index === item.loads.length - 1 && item.inspectionDate) {
                // Calculate time difference for the last load
                loadTotalMinutes = calculateTimeDifference1( load.printDateTime, item.inspectionDate);
            }

            return loadAcc + loadTotalMinutes;
        }, 0);

        return acc + totalMinutesFromLoads + inspectionInMinutes + inspectionOutMinutes;
    }, 0);
}

function calculateTimeDifference1(printDateTime: string, inspectionDateISO: string): number {
  const printDate = new Date(printDateTime);
  const inspectionDate = new Date(inspectionDateISO);
  const difference = inspectionDate.getTime() - printDate.getTime(); // This will be in milliseconds
  return Math.floor(difference / 60000); // Convert milliseconds to minutes and ensure it's a number
}


const totalIsManual = data.reduce((acc, item) => acc + item.loads.reduce((loadAcc, load) => loadAcc + (load.isManual == 1 ? 1 : 0), 0), 0);
const totalLoads = data.reduce((acc, item) => acc + item.loads.length, 0);
const totalNotes = data.reduce((acc, item) => acc + item.loads.reduce((loadAcc, load) => loadAcc + (load.notes.trim() !== "" ? 1 : 0), 0), 0);

const formatDate = (dateString: string): string => {
  // Extract the date portion before the 'T' character
  const datePortion = dateString.split('T')[0];
  
  return datePortion;
};

function calculateTimeDifference3(lastRecordTime: string, inspectionDateISO: string): string {
  
  if(inspectionDateISO.startsWith("1970-01-0"))
      return '0';

  // Ensure both inputs are strings and not null or undefined
  if (typeof lastRecordTime !== 'string' || typeof inspectionDateISO !== 'string') {
    throw new Error("Invalid input types");
  }

  // Create Date object from ISO string
  const inspectionDate = new Date(inspectionDateISO);

  // Clone the inspection date to adjust to the last record time
  const lastRecordDateTime = new Date(inspectionDateISO);
  const lastRecordTimeParts = lastRecordTime.split(':');
  if (lastRecordTimeParts.length !== 2) {
    throw new Error("Invalid time format");
  }

  // Parse hours and minutes and set them in UTC
  const hours = parseInt(lastRecordTimeParts[0], 10);
  const minutes = parseInt(lastRecordTimeParts[1], 10);
  if (isNaN(hours) || isNaN(minutes)) {
    throw new Error("Invalid time components");
  }
  lastRecordDateTime.setUTCHours(hours);
  lastRecordDateTime.setUTCMinutes(minutes);
  lastRecordDateTime.setUTCSeconds(0);
  lastRecordDateTime.setUTCMilliseconds(0);

  // Ensure both date objects are converted to timestamps for arithmetic
  const inspectionTimestamp = inspectionDate.getTime();
  const lastRecordTimestamp = lastRecordDateTime.getTime();

  // Calculate the difference in minutes
  const diff = (inspectionTimestamp - lastRecordTimestamp) / (1000 * 60);
  const hoursDiff = Math.floor(Math.abs(diff) / 60);
  const minutesDiff = Math.abs(diff) % 60;

  // Return formatted string ensuring hours are also treated uniformly
return convertToTimeFormat(`${hoursDiff}:${minutesDiff < 10 ? '0' + minutesDiff : minutesDiff}`);
}


function calculateTimeDifference(startTime: string, endTime: string): string {

  const [startHours, startMinutes] = startTime.split(':').map(Number);
  const startTotalMinutes = startHours * 60 + startMinutes;

  // Parse the end time
  const [endHours, endMinutes] = endTime.split(':').map(Number);
  const endTotalMinutes = endHours * 60 + endMinutes;

  // Calculate the difference in minutes
  let diff = endTotalMinutes - startTotalMinutes;

  // Handle overnight scenario
  if (diff < 0) {
    diff += 24 * 60; // Add 24 hours in minutes
  }

  // Calculate hours and minutes from the difference
  const hours = Math.floor(diff / 60);
  const minutes = diff % 60;

  

 return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
 

}

function calculateTimeDifference2(startTime: string, endTime: string, truckId:string): string {
  // Parse the start time

  const [startHours, startMinutes] = startTime.split(':').map(Number);
  const startTotalMinutes = startHours * 60 + startMinutes;

  // Parse the end time
  const [endHours, endMinutes] = endTime.split(':').map(Number);
  const endTotalMinutes = endHours * 60 + endMinutes;

  // Calculate the difference in minutes
  let diff = endTotalMinutes - startTotalMinutes;

  // Handle overnight scenario
  if (diff < 0) {
    diff += 24 * 60; // Add 24 hours in minutes
  }

  // Calculate hours and minutes from the difference
  const hours = Math.floor(diff / 60);
  const minutes = diff % 60;

  if (isNaN(hours) || isNaN(minutes)) {
    return "0:00";
  }
  // Format the result as "hh:mm"
  return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
}

function convertToTimeFormat(timeStr:any) {
  const [hours, decimalMinutes] = timeStr.split(':');
  const minutes = Math.floor(decimalMinutes);
  const fractionalPart = decimalMinutes - minutes;
  const seconds = Math.round(fractionalPart * 60);

  return `${hours}:${seconds < 10 ? '0' + seconds : seconds}`;
}

function formatDuration(totalMinutes:number) {
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;
  return `${hours}:${minutes.toString().padStart(2, '0')}`;
}
  function getTime(printtime: string | null){
    // Parse the string into a Date object
    if(printtime== null || printtime==""){
      return "";
    }

    const date = new Date(printtime);

    // Get the hours and minutes in UTC
    const hours = date.getUTCHours();
    const minutes = date.getUTCMinutes();

    // Format the hours and minutes to ensure two digits
    const formattedHours = hours.toString().padStart(2, '0');
    const formattedMinutes = minutes.toString().padStart(2, '0');

    // Combine hours and minutes into a hh-mm format
    const time = `${formattedHours}:${formattedMinutes}`;

    return time;
  }
  
  function formatMinutesToHours(minutes:any) {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    return `${hours}:${remainingMinutes.toString().padStart(2, '0')}`;
}
  return (
    <>
    <div className="widgets-container">
      <div className="widget">
        <h5>Total Names</h5>
        <p>{totalNames}</p>
      </div>
      <div className="widget">
        <h5>Total Hours</h5>
        <p>{formatMinutesToHours(calculateTotalMinutes(data))}</p> 
      </div>
      <div className="widget">
        <h5>Manual Loads</h5>
        <p>{totalIsManual}</p>
      </div>
      <div className="widget">
        <h5>Total Loads</h5>
        <p>{totalLoads}</p>
      </div>
      <div className="widget">
        <h5>Total Notes</h5>
        <p>{totalNotes}</p>
      </div>
    </div>
    <div>
    <label style={{ fontSize: '14px' , padding:10}}>
      <input
        type="checkbox"
        checked={showTime}
        onChange={handleCheckboxChange}
        style={{ marginRight: '10px' }} // Add some space between the checkbox and the label text
      />
      Display time
    </label>
    <label style={{ fontSize: '14px', padding:10}}>
      <input
        type="checkbox"
        checked={showManualLoads}
        onChange={handleIsManualLoads}
        style={{ marginRight: '10px' }} // Add some space between the checkbox and the label text
      />
      Display Manual Loads Only
    </label>
    
    </div>
    <table className="table-style">
      <thead>
        <tr>
          <th>Truck</th>
          <th>Name</th>
          <th>PreTrip</th>
          {Array.from({ length: 20 }, (_, i) => <th key={i}> {i + 1}</th>)}
          <th>PostTrip</th>
          <th>Total Hours</th>
          <th>Elapsed</th>
          <th>Notes</th>
        </tr>
      </thead>
      <tbody>
        {data.map((item, itemIndex) => (
           <tr key={itemIndex}>
           <td>{item.truckId}</td>
           <td onClick={() => handleNameClick(item)} style={{cursor: 'pointer'}}>{item.name}</td>
            <td  className={
                    (() => {  
                      return Number(item.inspectionIn) > 120 ? 'red' : ''; 
                    })()
                  }>
            <>
            {item.inspectionIn?formatDuration(item.inspectionIn):""}
            <br/>
            {formatEST(item.preTripDate)}
              {/* {item.inspectionIn?formatDate1(item.preTripDate).substring(11, 16):""} */}
             </>
            </td>
              {item.loads.slice(0, 20).map((load, loadIndex) => (
                <td 
                  key={loadIndex} 
                  className={
                    (() => {
                      let minutes;
                      if (loadIndex === item.loads.length - 1) {
                        const inspectionDate = new Date(item.inspectionDate);
                        const timeDifference = calculateTimeDifference(adjustTime(load.printDateTime),formatEST(item.inspectionDate));
                        const parts = timeDifference.split(':');
                        minutes = parseInt(parts[0], 10) * 60 + parseInt(parts[1], 10);
                      } else {
                        const parts = load.minutes.split(':');
                        minutes = parseInt(parts[0], 10) * 60 + parseInt(parts[1], 10);
                      }
                      return minutes > 120 ? 'red' : ''; 
                    })()
                  }
                  onClick={() => handleCellClick(load, loadIndex)} 
                  style={{cursor: 'pointer'}}
                >
                  {(!showManualLoads || load.isManual === 2) ? (
                    <>
                    
                      {loadIndex === item.loads.length - 1
                        ? calculateTimeDifference2(adjustTime(load.printDateTime),formatEST(item.inspectionDate),item.truckId)
                        : load.minutes}
                      <br/>
                      {showTime ? adjustTime(load.printDateTime) : null}
                    </>
                  ) : ' '}
                </td>
         
          
            ))}
            {item.loads.length < 20 &&
              Array.from({ length: 20 - item.loads.length }, (_, i) => 
              <td key={i + item.loads.length}></td>)
            }
      <td  className={
                    (() => {  
                      return Number(item.inspectionOut) > 60 ? 'red' : ''; 
                    })()
                  }>
      <>
      {item.inspectionOut?formatDuration(item.inspectionOut)!="0:00"?
      <div>
          {item.inspectionOut?formatDuration(item.inspectionOut): 'N/A'}
          <br />
          {formatEST(item.inspectionDate)}
          </div>
      
      :<div></div>:""}
          </>
  </td>
  <td>
{(() => {
    // Helper function to convert hour decimals to minutes
    const convertToMinutes = (hoursDecimal:any) => Math.round(hoursDecimal * 60);
    const convertToMinutes1 = (hoursDecimal:any) => hoursDecimal;
    // Helper function to parse time in "h:mm" format and return total minutes
    const parseTime = (timeStr:any) => {
        const [hours, minutes] = timeStr.split(':').map(Number);
        return hours * 60 + minutes;
    };

    const totalMinutes = item.loads.reduce((sum, load, index) => {
        let loadTime = load.minutes;
        console.log(`Original load time for index ${index}: ${loadTime}`); // Debugging output

        // Handle last load time calculation based on inspection date
        if (index === item.loads.length - 1) {
            if (item.inspectionDate) {
                loadTime = calculateTimeDifference(adjustTime(load.printDateTime),formatEST(item.inspectionDate));
                console.log(`Adjusted load time for last index (${index}): ${loadTime}`); // Debugging output
            } else {
                loadTime = '0:0';  // Default to 0 minutes if no inspection date
            }
        }

        // Check if loadTime is a string and contains ':', otherwise assume it's a decimal hour format
        const minutesToAdd = (typeof loadTime === 'string' && loadTime.includes(':')) ? parseTime(loadTime) : convertToMinutes(loadTime);
        console.log(`Minutes to add for index ${index}: ${minutesToAdd}`); // Debugging output
        return sum + minutesToAdd;
    }, convertToMinutes1(Number(item.inspectionIn)) + convertToMinutes1(Number(item.inspectionOut) ?? 0));

    console.log(`Total minutes calculated: ${totalMinutes}`); // Final debugging output

    // Calculate hours and minutes for display
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    const style = hours > 14 ? { color: 'red', cursor: 'pointer' } : { cursor: 'pointer' };

    return <span style={style}>{`${hours}:${minutes.toString().padStart(2, '0')}`}</span>;
})()}
</td>


<td>{item.preTripDate && item.inspectionDate !==null? calculateTimeDifference3(getTime(item.preTripDate.toString()), item.inspectionDate.toString()):""}</td>

            {/* New cell for notes */}
            <td>
              {item.loads.some(load => load.notes) ? (
                <button
                  style={{ background: "none", border: "none", padding: 0, cursor: "pointer", color: "blue" }}
                  onClick={() => handleNoteClick(item.loads.map(load => load.notes))}
                >
                  *
                </button>
              ) : null}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
    {drawerVisible && (
        <div className="drawer">
          <p style={{marginLeft:20}}> Driver: {drawerContent?.name}</p>
          <button onClick={closeDrawer} style={{marginLeft:"80%"}}>Close</button>
          {contentType==0?
          <div>
           
          {/* <table className="table-style">
            <thead>
                <tr>
                    <th>Hours</th>
                    <th>Date</th>
                    <th>Status</th>
                </tr>
            </thead>
            <tbody>
                {driverItems.map((item, index) => (
                    <tr key={index}>
                        <td onClick={() => handledayClick(item)} style={{cursor: 'pointer'}}>{(Number(item.minutes)/60).toFixed(2)}</td>
                        <td>{formatDate(item.dateRef)}</td>
                        <td>{item.isApproved}</td>
                    </tr>
                ))}
            </tbody>
        </table> */}
                  {/* <hr/> */}
       
                  <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}>
        {/* <button onClick={handelApprov} style={{height:30}}>Approve</button>
        <button onClick={handelReject} style={{height:30}}>Reject</button>
              */}
        </div>
        <hr/>

        <h4>Details {day}</h4>
        <div className="table-container">
        <table className="table-style">
            <thead>
                <tr>
                    <th>Ticket#</th>
                    <th>TruckID</th>
                    <th>Phase#</th>
                    <th>PrintDateTime</th>
                    <th>Job</th>
                    <th>ProductID</th>
                    <th>OrderDescription</th>
                    <th>CustomerID</th>

                </tr>
            </thead>
            <tbody>
                { detailsItems && detailsItems.length>0 && detailsItems.map((item, index) => (
                    <tr key={index}>
                        <td>{item.TicketNumber}</td>
                        <td>{item.TruckID}</td>
                        <td>{item.Phase}</td>
                        <td>{adjustTime(item.PrintDateTime)}</td>
                        <td>{item.Job}</td>
                        <td>{item.ProductID}</td>
                        <td>{item.OrderDescription}</td>
                        <td>{item.CustomerID}</td>
                    </tr>
                ))}
            </tbody>
        </table>
        </div>
       
          </div>
         :<div style={{marginLeft:20}}>
            <h4>Load Details</h4>
            
            <p> Scale Time# : { drawerContent1?.printDateTime!= null?adjustTime(drawerContent1?.printDateTime):""}</p>
            <p> Job: {drawerContent1?.job}</p>
            <p> Customer : {drawerContent1?.customerName}</p>
            <p> Phase : {drawerContent1?.phase}</p>
            <p> Order Descr : {drawerContent1?.orderDescription}</p>
            <p> Product Desc: : {drawerContent1?.productDescription}</p>
          </div>}
        </div>
      )}
    </>
  );
}

export default MainContent;
