import React, { useEffect, useState } from 'react';
import './compstyle.css'; 
import dashboardService from '../../apis/dashboardService'; 
interface Load {
  start: string;
  End: string;
  minutes: number;
  isManual: number;
  notes: string;
}

interface DataRecord {
  DriverName: string;
  Status: string;
  DateRef: string;
  minutes: string;
}

interface DriverItem {
  minutes: string;
  dateRef: string;
  isApproved: string;
}

interface DataItem {
  name: string;
  inspectionIn: number;
  inspectionOut: number | null;
  truckId: string;
  loads: Load[];
}

interface MainContentProps {
  data: DataItem[];
}

function Stauts({ data }: MainContentProps) {

  const [items, setItems] = useState<DataRecord[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [type, setType] = useState('');
  const [day, setDay] = useState('');
  const [searchTerm, setSearchTerm] = useState(''); // State to store search term
  const [statusTerm, setstatusTerm] = useState(''); // State to store search term
  const [dateTerm, setDateTerm] = useState(''); // State to store search term
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
     
      try {
       
        const response = await dashboardService.getStatusSum({ type, day });
        const rawData = response; // Adjust according to how you access the API response
       
        if(rawData.isValid==false){
          setItems([]);
        }
        
        
        setItems(rawData); // Convert the grouped object to an array
        setIsLoading(false);
      } catch (error) {

        console.error("Failed to fetch data:", error);
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const formatDate = (dateString: string): string => {
    // Extract the date portion before the 'T' character
    const datePortion = dateString.split('T')[0];
    
    return datePortion;
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value); // Update search term as user types
  };

  const handleSearchChange1 = (e: React.ChangeEvent<HTMLInputElement>) => {
    setstatusTerm(e.target.value); // Update search term as user types
  };

  const handleSearchChange2 = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDateTerm(e.target.value); // Update search term as user types
  };

  const filteredItems = items.filter(item =>
    (item.DriverName || '').toLowerCase().includes(searchTerm.toLowerCase()) 
    &&
    item.Status.includes(statusTerm)
    && item.DateRef.includes(dateTerm)
  );
  
  const totalHours = filteredItems.reduce((acc, item) => acc + (Number(item.minutes) || 0), 0);
  
  const exportToCsv = () => {
    const headers = "Driver Name,Hours,Status,Date\n";
    const rows = filteredItems.map(item => 
      `${item.DriverName},${(Number(item.minutes) / 60).toFixed(2)},${item.Status},${formatDate(item.DateRef)}`
    ).join("\n");

    const csvContent = headers + rows;
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", "export.csv");
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      <div>
        <div className="grid-container">
        <input
          type="text"
          placeholder="Search by driver name..."
          value={searchTerm}
          onChange={handleSearchChange}
          className="search-input"
        />
       
         <input
          type="text"
          placeholder="Search by status..."
          value={statusTerm}
          onChange={handleSearchChange1}
          className="search-input"
        />
        <input
          type="text"
          placeholder="Search by date"
          value={dateTerm}
          onChange={handleSearchChange2}
          className="search-input"
        />
        <button onClick={exportToCsv} className="export-button">Export to CSV</button>
        </div>

        <div>
          <p>Count:{filteredItems.length} Hours:{(totalHours/60).toFixed(2)}</p>
        </div>
        <table className="table-style1">
          <thead>
            <tr>
              <th>Driver Name</th>
              <th>Hours</th>
              <th style={{width:40}}>Status</th>
              <th>Date</th>
            </tr>
          </thead>
          <tbody>
            {filteredItems.map((item, itemIndex) => (
              <tr key={itemIndex}>
                <td>{item.DriverName}</td>
                <td>{(Number(item.minutes)/60).toFixed(2)}</td>
                <td>{item.Status =="Pending"?"P":item.Status}</td>
                {/* <td>{item.HasRejected}</td> */}
                <td>{formatDate(item.DateRef)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
}

export default Stauts;
