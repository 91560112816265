import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Login from './screens/Login';
import DashboardScreen from './screens/Dashboard';
import ProtectedRoute from './ProtectedRoute'; 

function MyRoutes() {
  return (
      <Routes> {/* Use Routes for defining your route configuration */}
        <Route path="/" element={<Login />} />
        <Route path="/dashboard" element={
          <ProtectedRoute>
            <DashboardScreen />
          </ProtectedRoute>
        } />
      </Routes>
  );
}

export default MyRoutes;
