import axios from 'axios';

const API_BASE_URL = 'https://vicci2.com/api/transactions/today';

type transaction = {
  type: string;
  transDate: Date;
  truckId: string;
};

interface Load {
  start: string;
  End: string;
  minutes: number;
  isManual: number; 
  notes: string
}

interface DataItem {
  name: string;
  inspectionIn: number;
  inspectionOut: number | null;
  truckId: string;
  loads: Load[];
}

interface ApiResponse {
  data: DataItem[]; // Use the actual structure of your API response
}

function formatDate1(dateString:Date) {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Adding 1 because months are zero-indexed
  const day = String(date.getDate()).padStart(2, '0');
  
  return `${year}-${month}-${day}`;
}

function formatDate(date:Date) {
  const year = date.getFullYear();
  const month = date.getMonth() + 1; // getMonth() is zero-based
  const day = date.getDate();

  // Pad the month and day with leading zeros if necessary
  return `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
}
const dashboardServices = {
  getData: async (user: transaction) => {
    var transDate = formatDate(user.transDate);
    try {
       const response = await axios.post("https://vicci2.com/api/transactions/today",{transDate: transDate});
       
       return response.data;
    
    } catch (error) {
      alert(error)
      return null;
     // throw error;
    }
  },
 
  driverDetails: async (user: any) => {
    try {
       const response = await axios.post("https://vicci2.com/api/transactions/truckDetails",
       {truckId: user.type, transDate: user.sDate});
     
       return response.data;
    
    } catch (error) {
      alert(error)
      return null;
     // throw error;
    }
  },
  driverData: async (user: any) => {
 
    try {
       const response = await axios.post("https://vicci2.com/api/transactions/Driver2",user);
     
       return response.data;
    
    } catch (error) {
      alert(error)
      return null;
     // throw error;
    }
  },
  driverData1: async (user: any) => {
   
    try {
       const response = await axios.post("https://vicci2.com/api/transactions/Driver1",{truckID: user.type, DateRef: user.transDate});
       return response.data;
    
    } catch (error) {
      alert(error)
      return null;
     // throw error;
    }
  },

  dayApprove: async (user: any) => {
    try {
       const response = await axios.post("https://vicci2.com/api/transactions/Driver/DayApprove", 
       {driverName: user.type, transDate: user.day});
       return response.data;
    
    } catch (error) {
      alert(error)
      return null;
    }
  },
  dayRejected: async (user: any) => {
    try {
       const response = await axios.post("https://vicci2.com/api/transactions/Driver/DayReject", 
       {driverName: user.type, transDate: user.day});
       return response.data;
    } catch (error) {
      alert(error)
      return null;
    }
  },
  getSum: async (user: any) => {
    try {
       const response = await axios.post("https://vicci2.com/api/transactions/getSum");
       return response.data;
    } catch (error) {
      alert(error)
      return null;
    }
  },
  getStatusSum: async (user: any) => {
    try {
       const response = await axios.post("https://vicci2.com/api/transactions/StautsCount");
      
       return response.data;
    } catch (error) {
      alert(error)
      return null;
    }
  },
  getStatusSum1: async (user: any) => {
    try {
       const response = await axios.post("https://vicci2.com/api/transactions/StautsCount1");
      
       return response.data;
    } catch (error) {
      alert(error)
      return null;
    }
  },
  lastTwoWeeks: async (user: any) => {
    try {
       const response = await axios.post("https://vicci2.com/api/transactions/LastTwoWeeks",user);
       return response;
    } catch (error) {
  
      alert(error)
      return null;
    }
  },
  exportData: async (user: any) => {
    try {
       const response = await axios.post("https://vicci2.com/api/transactions/ExportData",user);
       return response;
    } catch (error) {
  
      alert(error)
      return null;
    }
  },
  Inspections: async (user: any) => {
    try {
       const response = await axios.post("https://vicci2.com/api/transactions/InspectionLastTwoWeeks");
       return response;
    } catch (error) {

      alert(JSON.stringify(error))
      alert(error)
      return null;
    }
  },
  approveItem: async (user: any) => {
   
    try {
       const response = await axios.post("https://vicci2.com/api/transactions/approveItem", 
       {truckId: user.truckId, date: user.date,driverId:user.driverId});
       return response;
    } catch (error) {
      alert("errr1")
      alert(error)
      return null;
    }
  },
  pendingItem: async (user: any) => {
   
    try {
       const response = await axios.post("https://vicci2.com/api/transactions/pendingItem", 
       {truckId: user.truckId, date: user.date,driverId:user.driverId});
       return response;
    } catch (error) {
      alert("errr1")
      alert(error)
      return null;
    }
  },
  rejectItem: async (user: any) => {
    try {
       const response = await axios.post("https://vicci2.com/api/transactions/rejectItem", 
       {truckId: user.truckId, date: user.date,driverId:user.driverId, Comment: user.Comment});
       return response;
    } catch (error) {
      alert("errr1")
      alert(error)
      return null;
    }
  },
  getUsers: async () => {
    try {
       const response = await axios.post("https://vicci2.com/api/users/users");
       return response;
    } catch (error) {
      alert(error)
      return null;
    }
  },
  addUser: async (user:any) => {
    alert(JSON.stringify(user));
    try {
       const response = await axios.post("https://vicci2.com/api/users/AddUser",user);
       return response;
    } catch (error) {
      alert("errr" + error)
      return null;
    }
  },
  deleteUser: async (user:any) => {
   
    try {
       const response = await axios.post("https://vicci2.com/api/users/deleteUser",{userId:user});
       
       return response;
    } catch (error) {
      return null;
    }
  },
};

export default dashboardServices;
