import React, { useEffect, useState } from 'react';
import './compstyle.css'; 
import dashboardService from '../../apis/dashboardService'; 
interface Load {
  start: string;
  End: string;
  minutes: number;
  isManual: number;
  notes: string;
}



interface DataRecord {
  records: number;
  minutes: string;
  dateRef: string;
  CustomerCount: string;
}

interface DriverItem {
  minutes: string;
  dateRef: string;
  isApproved: string;
}

interface DataItem {
  name: string;
  inspectionIn: number;
  inspectionOut: number | null;
  truckId: string;
  loads: Load[];
}

interface MainContentProps {
  data: DataItem[];
}

function Charts({ data }: MainContentProps) {

  const [items, setItems] = useState<DataRecord[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [type, setType] = useState('');
  const [day, setDay] = useState('');
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
     
      try {
       
        const response = await dashboardService.getSum({ type, day });
        const rawData = response; // Adjust according to how you access the API response
       
        if(rawData.isValid==false){
          setItems([]);
        }
        
        
        setItems(rawData); // Convert the grouped object to an array
        setIsLoading(false);
      } catch (error) {

        console.error("Failed to fetch data:", error);
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  
 
 




  const formatDate = (dateString: string): string => {
    // Extract the date portion before the 'T' character
    const datePortion = dateString.split('T')[0];
    
    return datePortion;
  };


  const exportToCsv = () => {
    const headers = "Date,Loads,Customers,Hours\n";
    const rows = items.map(item => 
      `${item.dateRef},${(Number(item.records)).toFixed(2)},${Number(item.CustomerCount)},${Number(item.minutes)}`
    ).join("\n");

    const csvContent = headers + rows;
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", "LoadsPerDay.csv");
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
   
   <div>
   <button onClick={exportToCsv} className="export-button">Export to CSV</button>
   <table className="table-style1">
      <thead>
        <tr>
          <th style={{width:50}}>Date</th>
          <th style={{width:70}}>Loads</th>
          <th style={{width:60}}>Customers</th>
          <th style={{width:65}}>minutes</th>
        </tr>
      </thead>
      <tbody>
        {items.map((item, itemIndex) => (
           <tr key={itemIndex}>
            <td style={{width:50}}> {formatDate(item.dateRef)}</td>
            <td style={{width:70}}>{item.records}</td>
            <td style={{width:60}}>{item.CustomerCount}</td>
            <td style={{width:65}}>{item.minutes}</td>
          </tr>
        ))}
      </tbody>
    </table>
        </div>
      
    </>
  );
}

export default Charts;
