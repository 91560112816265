import React, { useEffect, useState } from 'react';
import './compstyle.css';
import dashboardService from '../../apis/dashboardService';
import moment from 'moment';

interface DataItem {
  createdDate: Date;
  FullName: string;
  driverId: string;
  deviceName: string;
  PreTripDuration: number;
  PostTripDuration: number;
}

interface GroupedData {
  [fullName: string]: {
    [date: string]: {
      PreTripDuration: number;
      PostTripDuration: number;
    };
  };
}

function Inspections() {
  const [pivotData, setPivotData] = useState<GroupedData>({});
  const [dates, setDates] = useState<string[]>([]);

  function convertDuration(timestamp:any) {
    if (!timestamp || timestamp.trim() === '') {
      return { formatted: '—', minutes: 0, seconds: 0 };
    }
  
    // Strip out fractional seconds if present to ensure correct parsing
    const cleanTimestamp = timestamp.split('.')[0];
  
    const parts = cleanTimestamp.split(':');
    let hours = 0, minutes = 0, seconds = 0;
  
    switch (parts.length) {
      case 3:
        // Normal HH:MM:SS format
        hours = parseInt(parts[0], 10);
        minutes = parseInt(parts[1], 10);
        seconds = parseInt(parts[2], 10);
        break;
      case 2:
        // Only MM:SS format, assume zero hours
        minutes = parseInt(parts[0], 10);
        seconds = parseInt(parts[1], 10);
        break;
      default:
        // Log error if format is unexpected
        console.error("Unexpected time format:", timestamp);
        return { formatted: '--', minutes: 0, seconds: 0 };
    }
  
    const displayHours = hours.toString().padStart(2, '0');
    const displayMinutes = minutes.toString().padStart(2, '0');
    const displaySeconds = seconds.toString().padStart(2, '0');
  
    return {
      formatted: `${displayHours}:${displayMinutes}:${displaySeconds}`,
      minutes: hours * 60 + minutes,
      seconds: seconds
    };
  }
  

  
  
  
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await dashboardService.Inspections({});
        if (response && response.data) {
          console.log(response.data)
          let newGroupedData: GroupedData = {};
          let uniqueDates = new Set<string>();
  
          response.data.forEach((item: DataItem) => {
            //const dateStr = moment(item.createdDate).format('YYYY-MM-DD');
            const dateStr = moment.utc(item.createdDate).format('YYYY-MM-DD');

            const { FullName, PreTripDuration, PostTripDuration } = item;
            
            uniqueDates.add(dateStr);
          
            if (!newGroupedData[FullName]) {
              newGroupedData[FullName] = {};
            }
          
            if (!newGroupedData[FullName][dateStr]) {
              newGroupedData[FullName][dateStr] = { PreTripDuration: 0, PostTripDuration: 0 };
            }
          
            newGroupedData[FullName][dateStr].PreTripDuration += PreTripDuration;
            newGroupedData[FullName][dateStr].PostTripDuration += PostTripDuration;
          });
          
          setDates(Array.from(uniqueDates).sort());
          setPivotData(newGroupedData);
        } else {
          console.error("API returned null or invalid response.");
          setPivotData({});
          setDates([]);
        }
      } catch (error) {
        console.error("Failed to fetch data:", error);
      }
    };
  
    fetchData();
  }, []);
  
  return (
    <div>
      <p>Duration longer than 40 mins will be marked as red’</p>
      <table className="table-style">
        <thead>
          <tr>
            <th>Driver</th>
            {dates.map((date, index) => (
              <th key={index}>{date}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {Object.entries(pivotData).map(([FullName, datesData], index) => (
            <tr key={index}>
              <td>{FullName}</td>
              {dates.map((date, idx) => (
  <td key={idx} className="inspection-duration">
    <div className={
      convertDuration(datesData[date]?.PreTripDuration).minutes > 40 ? 
      "duration-split high-duration" : "duration-split"
    }>
      {convertDuration(datesData[date]?.PreTripDuration).formatted || '—'}
    </div>
    <div className={
      convertDuration(datesData[date]?.PostTripDuration).minutes > 40 ? 
      "duration-split high-duration" : "duration-split"
    }>
      {convertDuration(datesData[date]?.PostTripDuration).formatted || '—'}
    </div>
  </td>
))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default Inspections;
