import React, { useEffect, useState } from 'react';
import './compstyle.css';
import dashboardService from '../../apis/dashboardService';
import moment from 'moment';

interface DataItem {
  driverName: string;
  dateRef: string;
  totalMinutes: string;
  Status: string;
}
interface DataItem1 {
  dateRef: string;
  driverId?: string;
  totalMinutes: string;
  DestinationTable?: string;
  Mth?: string;
  PostDate?: string;
  Type?: string;
  Job?: string;
  Phase?: string;
  EarnCode?: string;
  Co?: number;
  JCCo?: number;
  Craft?: string;
  Class?: string;
  Shift?: string;
  Amount?: string;
}




interface DriverDates {
  [dateRef: string]: string; // Assuming totalMinutes are stored as strings
}

interface GroupedData {
  [driverName: string]: DriverDates;
}

interface DayApprovals {
  [driverName: string]: {
    [dateRef: string]: string; // Status is a string
  };
}

interface DayTotals {
  [dateRef: string]: string; // Assuming totalMinutes are stored as strings for some reason
}

interface ApprovalInfo {
  [key: string]: {
    [key: string]: string; // Assuming the status is always a string
  };
}

const dayApprovals: ApprovalInfo = {};


interface ApiResponse {
  isValid: boolean;
  data: DataItem[];
}


interface MainContentProps {
  data: DataItem[];
}

interface DriverItem {
  minutes: string;
  dateRef: string;
  isApproved: string;
}

interface TicketData {
  TicketKey: number;
  TruckID: string;
  TruckKey: number;
  DriverName: string;
  Phase: string;
  TicketDate: string;
  Operator: string;
  Note: string;
  GrossWeight: string;
  TareWeight: string;
  PrintDateTime: string;
  DeliveredDateTime: string | null;
  MaterialAmount: string | null;
  MPTicketNumber: string | null;
  TicketNumber: number;
  NetWeight: string;
  Job: string;
  JobCompanyCode: string;
  ManualWeight: number;
  CostType: string;
  Version: number;
  OrderKey: number;
  OrderDescription: string;
  CustomerName: string;
  Address1: string;
  CustomerID: string;
  OrderProductCostType: string;
  OrderProductDescription: string;
  ProductID: string;
  ProductCategory: string;
  ProductKey: number;
  ProductDescription: string;
  isManual: number;
  notes: string;
  dateRef: string;
  FullName: string;
}


interface MinutesByDate {
  [dateRef: string]: string;
}


  const getMountainTimeDate = () => {
    const now = new Date(); // Gets the current date and time in the user's time zone
    const utc = Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), now.getUTCHours(), now.getUTCMinutes(), now.getUTCSeconds());
    const mountainTimeOffset = -6; // Mountain Daylight Time (MDT) offset from UTC
    return new Date(utc + mountainTimeOffset * 3600 * 1000); // Adjusts the date object by the offset
  
  };
  
  interface Load {
    start: string;
    End: string;
    minutes: number;
    isManual: number;
    notes: string;
    job: string;
    customerName: string
    phase: string,
    ticketNumber: string;
    orderDescription: string;
    productDescription: string;
    printDateTime: string;
  }

function LastTwoWeeks({ data }: MainContentProps) {
  const [pivotData, setPivotData] = useState<{ [key: string]: { [date: string]: string } }>({});
  const [dates, setDates] = useState<string[]>([]);
 const [dayTotals, setDayTotals] = useState<{ [date: string]: string }>({});
 const [drawerContent, setDrawerContent] = useState<DataItem | null>(null);
 const [type, setType] = useState('');
 const [drawerVisible, setDrawerVisible] = useState(false);
 const [contentType, setContentType] = useState<Number>(0)
 const [day, setDay] = useState('');
 const [driverItems, setDriverItems] = useState<DriverItem[]>([]);
 const [detailsItems, setDetailsItems] = useState<TicketData[]>([]);
 const [transDate, setTransDate] = useState(getMountainTimeDate());
 const [drawerContent1, setDrawerContent1] = useState<Load | null>(null);
 const [dayApprovals, setDayApprovals] = useState<DayApprovals>({});
 const [exportdata, setExportData] = useState<DataItem1[]>([]);
 
  // Helper function to parse date strings and find the current range
  const getCurrentDateRange = () => {
    const today = new Date();
    return dateRanges.find(range => {
      const [start, end] = range.split(' | ').map(date => new Date(date));
      return today >= start && today <= end;
    }) || '';
  };

 const dateRanges = [
 // "2024-04-21 | 2024-05-04",
 // "2024-05-05 | 2024-05-18",
  "2024-07-14 | 2024-07-27",
  "2024-06-30 | 2024-07-13",
  "2024-06-16 | 2024-06-29",
  "2024-06-02 | 2024-06-15",
  "2024-05-19 | 2024-06-01",
  
 
 // "2024-07-28 | 2024-08-10",
 // "2024-08-11 | 2024-08-24",
 // "2024-08-25 | 2024-09-07",
 // "2024-09-08 | 2024-09-21",
 // "2024-09-22 | 2024-10-05",
 // "2024-10-06 | 2024-10-19",
 // "2024-10-20 | 2024-11-02",
 // "2024-11-03 | 2024-11-16",
 // "2024-11-17 | 2024-11-30",
 // "2024-12-01 | 2024-12-14",
 // "2024-12-15 | 2024-12-28"
];

const [selectedPeriod, setSelectedPeriod] = useState(getCurrentDateRange());
 useEffect(() => {
  const fetchData = async () => {
    try {
      const response = await dashboardService.lastTwoWeeks({period: selectedPeriod});
      if (response?.data.isValid === false || !response?.data) {
        setPivotData({});
        setDates([]);
        setDayTotals({});
        setDayApprovals({});
      } else {
        const rawData: DataItem[] = response.data;
        setExportData(rawData);
      
        let dayTotalsTemp: DayTotals = {};
        let dayApprovalsTemp: DayApprovals = {};

        const groupedData = rawData.reduce<GroupedData>((acc, item) => {
          const { driverName, dateRef, totalMinutes, Status } = item;

          if (!acc[driverName]) {
            acc[driverName] = {};
          }

          if (!dayApprovalsTemp[driverName]) {
            dayApprovalsTemp[driverName] = {};
          }

          acc[driverName][dateRef] = totalMinutes;
          dayTotalsTemp[dateRef] = totalMinutes;
          dayApprovalsTemp[driverName][dateRef] = Status;

          return acc;
        }, {});

        const uniqueDates = Array.from(new Set(rawData.map(item => item.dateRef))).sort();
        setDates(uniqueDates);
        setDayTotals(dayTotalsTemp);
        setDayApprovals(dayApprovalsTemp);
        setPivotData(groupedData);
      }
    } catch (error) {
      console.error("Failed to fetch data:", error);
    }
  };

  fetchData();
}, [selectedPeriod]); // This useEffect only runs once on component mount
interface DataItem2 {
  DestinationTable: string;
  Employee: string | null;
  PREndDate: string;
  Mth: string;
  PostDate: string;
  Type: string;
  Job: string;
  Phase: string;
  EarnCode: string;
  Hours: string;
  CO: string;
  JCCo: string;
  Craft: string;
  Class: string;
  Shift: number;
  Amount: string;
  TruckID: string;
}

const exportToCsv = async () => {
  const response = await dashboardService.exportData({ period: selectedPeriod });
  const data: DataItem2[] = response?.data; // Ensure this line has the correct type assertion

  if (!data || data.length === 0) {
    console.error('No data available to export');
    return;
  }

  // Extract headers from the first object
  const headers = Object.keys(data[0]) as (keyof DataItem2)[];
  const csvRows: string[] = [];
  csvRows.push(headers.join(',')); // Add headers row

  // Add data rows
  for (const row of data) {
    const values = headers.map(header => {
      const value = row[header];
      return (value === null || value === undefined) ? '' : `"${value}"`;
    });
    csvRows.push(values.join(','));
  }

  // Create CSV string
  const csvString = csvRows.join('\n');

  // Create a Blob from the CSV string
  const blob = new Blob([csvString], { type: 'text/csv' });
  const url = window.URL.createObjectURL(blob);

  // Create a link to download the CSV file
  const a = document.createElement('a');
  a.setAttribute('hidden', '');
  a.setAttribute('href', url);
  a.setAttribute('download', 'data.csv');
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};
 
  const handelReject = async () => {
   
    const response = await dashboardService.dayRejected({ type, day });
    const rawData = response; 
    if(rawData.isValid==true){
    alert("Rejected. data updated!");
    } else{
    alert("Something went wrong please try again;")
    }
  };

  const handelApprov = async () => {
   
    const response = await dashboardService.dayApprove({ type, day });
    const rawData = response; 
    if(rawData.isValid==true){
    alert("Approved. data updated!");
    } else{
    alert("Something went wrong please try again;")
    }
  };
  const formatDate = (dateString: string): string => {
    // Extract the date portion before the 'T' character
    const datePortion = dateString.split('T')[0];
    
    return datePortion;
  };

  const handledayClick = async (item: DriverItem) => {
   
    var sDate= formatDate(item.dateRef);
    setDay(sDate);
    const response = await dashboardService.driverDetails({ type, sDate });
    const rawData = response; // Adjust according to how you access the API response
    setDetailsItems(rawData);
  };

  const closeDrawer = () => {

    setDrawerVisible(false);
  }

  const handleNameClick = async (item: DataItem) => {
    
    setType(item.driverName);
    const response = await dashboardService.driverData({ driverName:item.driverName });
  
    const rawData = response;
    setDriverItems(rawData);
    setDrawerContent(item);
    setDrawerVisible(true);
  };



  function getTime(printtime: string | null){
    // Parse the string into a Date object
    if(printtime== null || printtime==""){
      return "";
    }

    const date = new Date(printtime);

    // Get the hours and minutes in UTC
    const hours = date.getUTCHours();
    const minutes = date.getUTCMinutes();

    // Format the hours and minutes to ensure two digits
    const formattedHours = hours.toString().padStart(2, '0');
    const formattedMinutes = minutes.toString().padStart(2, '0');

    // Combine hours and minutes into a hh-mm format
    const time = `${formattedHours}:${formattedMinutes}`;

    return time;
  }

  return (
    <div>
       <button onClick={exportToCsv} className="export-button">Export to CSV</button>

       <br/>
        <p>Select Period</p>
       <select value={selectedPeriod} onChange={e => setSelectedPeriod(e.target.value)}>
      
        {dateRanges.map((range, index) => (
          <option key={index} value={range}>{range}</option>
        ))}
         
          </select>
    
      <table className="table-style">
        <thead>
          <tr>
            <th>Driver</th>
            {dates.map((date, index) => (
             <th key={index}>{moment.utc(date).format('YYYY-MM-DD')}</th>
            ))}
            {/* <th>Total</th> */}
          </tr>
        </thead>
        <tbody>
  {Object.entries(pivotData).map(([driverName, datesData], index) => (
    <tr key={index}>
      <td style={{ maxWidth: 200, minWidth: 200 }} onClick={() => handleNameClick({
        driverName: driverName, ...datesData,
        totalMinutes: datesData.total,
        dateRef: '',
        Status: ''
      })}>{driverName}</td>
      {dates.map((date, index) => {
        // Access the status for the specific driver and date
        let statusClass = '';
        const status = (dayApprovals[driverName] as unknown as {[key: string]: string})?.[date];
      
        switch (status) {
          case 'Approved':
          case 'Auto Approve':
            statusClass = 'approved';
            break;
          case 'Rejected':
            statusClass = 'rejected';
            break;
          case 'Pending':
            statusClass = 'pending';
            break;
          default:
            statusClass = ''; // Default class or no class
            break;
        }
        return (
          <td key={index} className={statusClass}>{datesData[date] || '—'}</td>
        );
      })}
      {/* <td>{convertDuration(datesData.total)}</td>   */}
    </tr>
  ))}
</tbody>


      </table>

      {drawerVisible && (
        <div className="drawer">
          <p style={{marginLeft:20}}> Driver: {drawerContent?.driverName}</p>
          <button onClick={closeDrawer} style={{marginLeft:"80%"}}>Close</button>
          {contentType==0?
          <div>
           
          <table className="table-style">
            <thead>
                <tr>
                    <th>Minutes</th>
                    <th>Date</th>
                    <th>Status</th>
                </tr>
            </thead>
            <tbody>
                {driverItems.map((item, index) => (
                    <tr key={index}>
                        <td onClick={() => handledayClick(item)} style={{cursor: 'pointer'}}>{item.minutes}</td>
                        <td>{formatDate(item.dateRef)}</td>
                        <td>{item.isApproved}</td>
                    </tr>
                ))}
            </tbody>
        </table>
                  <hr/>
       
                  <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}>
        <button onClick={handelApprov} style={{height:30}}>Approve</button>
        <button onClick={handelReject} style={{height:30}}>Reject</button>
             
        </div>
        <hr/>

        <h4>Details {day}</h4>
        <div className="table-container">
        <table className="table-style">
            <thead>
                <tr>
                    <th>Ticket#</th>
                    <th>TruckID</th>
                    <th>Phase#</th>
                    <th>PrintDateTime</th>
                    <th>Job</th>
                    <th>ProductID</th>
                    <th>OrderDescription</th>
                    <th>CustomerID</th>

                </tr>
            </thead>
            <tbody>
                { detailsItems && detailsItems.length>0 && detailsItems.map((item, index) => (
                    <tr key={index}>
                        <td>{item.TicketNumber}</td>
                        <td>{item.TruckID}</td>
                        <td>{item.Phase}</td>
                        <td>{item.PrintDateTime}</td>
                        <td>{item.Job}</td>
                        <td>{item.ProductID}</td>
                        <td>{item.OrderDescription}</td>
                        <td>{item.CustomerID}</td>
                    </tr>
                ))}
            </tbody>
        </table>
        </div>
       
          </div>
         :<div style={{marginLeft:20}}>
            <h4>Load Details</h4>
            <p> Ticket# : {drawerContent1?.ticketNumber}</p>
            <p> Scale Time# : {drawerContent1?.printDateTime!= null?getTime(drawerContent1?.printDateTime):""}</p>
            <p> Job: {drawerContent1?.job}</p>
            <p> Customer : {drawerContent1?.customerName}</p>
            <p> Phase : {drawerContent1?.phase}</p>
            <p> Order Descr : {drawerContent1?.orderDescription}</p>
            <p> Product Desc: : {drawerContent1?.productDescription}</p>
          </div>}
        </div>
      )}
    </div>
  );
  
}

export default LastTwoWeeks;
