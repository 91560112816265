import axios from 'axios';

const API_BASE_URL = 'https://vicci2.com/api/users';

type User = {
  username: string;
  password: string;
};

const userService = {
  login: async (user: User) => {
   
    try {
      console.log(`${API_BASE_URL}/login`)
      const response = await axios.post(`${API_BASE_URL}/login`, user);
      console.log(JSON.stringify(response.data))
      return response.data;
     
    } catch (error) {
      throw error;
    }
  },
};

export default userService;
