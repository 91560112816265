import React, { useState } from 'react';
import './login.css'; 
import userService from '../apis/userService'; 
import { useNavigate } from 'react-router-dom'; 
import { useAuth } from '../AuthContext';

const Login = () => {
  const navigate = useNavigate(); 
  const { login } = useAuth();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  // Static company information
  const companyName = "Cavanagh";
  const supportPhone = "613-257-2918";
  const address = "9094 Cavanagh Road.Ashton, Ontario, K0A 1B0";

  const handleLogin = async () => {
    try {
      if (!username || !password) {
        setError('Please enter both username and password.');
        return;
      }

      const response = await userService.login({ username, password });
      if (response.isValid === true) {
        console.log('Login successful:', response);
        login(); 
        navigate('/dashboard'); 
        setUsername('');
        setPassword('');
        setError('');
      } else {
        console.error('Login error:', error);
        setError('Login failed. Please try again.');
      }
    } catch (error) {
      console.error('Login error:', error);
      setError('Login failed. Please try again.');
    }
  };

  return (
    <div>
     <div style={{ position: 'fixed', top: 100, width: 500, backgroundColor: 'white' }}>
      <center><h2>{companyName}</h2></center>
    </div>
    
    <div className="login-container">
      
      <h2>Login</h2>
      {error && <p className="error-message">{error}</p>}
      <form className="login-form" onSubmit={(e) => e.preventDefault()}>
        <div className="form-group">
          <label htmlFor="username">Username:</label>
          <input 
            className="form-control" 
            type="text" 
            id="username" 
            value={username} 
            onChange={(e) => setUsername(e.target.value)} 
          />
        </div>
        <div className="form-group">
          <label htmlFor="password">Password:</label>
          <input 
            className="form-control" 
            type="password" 
            id="password" 
            value={password} 
            onChange={(e) => setPassword(e.target.value)} 
          />
        </div>
        <button className="login-button" type="submit" onClick={handleLogin}>Login</button>
      </form>
      <div className="company-info">
     
        <p>Support: {supportPhone}</p>
        <p>{address}</p>
      </div>
    </div>
    </div>
  );
};

export default Login;
