import React, { useEffect, useState } from 'react';
import './compstyle.css'; 
import dashboardService from '../../apis/dashboardService'; 
interface Load {
  start: string;
  End: string;
  minutes: number;
  isManual: number;
  notes: string;
}


interface Load1 {
  start: string;
  End: string;
  minutes: string;
  isManual: number;
  notes: string;
  job: string;
  customerName: string
  phase: string,
  ticketNumber: string;
  orderDescription: string;
  productDescription: string;
  printDateTime: string;
}

interface DataRecord {
  driverId: string;
  checked: any;
  DriverName: string;
  Status: string;
  DateRef: string;
  minutes: string;
  truckId: string;
  PreTrip: string;
  PostTrip: string;
  total: string;
  Net: string,
  nonNullCount: number
  Comment: string
}

interface TicketData {
  TicketKey: number;
  TruckID: string;
  TruckKey: number;
  DriverName: string;
  Phase: string;
  TicketDate: string;
  Operator: string;
  Note: string;
  GrossWeight: string;
  TareWeight: string;
  PrintDateTime: string;
  DeliveredDateTime: string | null;
  MaterialAmount: string | null;
  MPTicketNumber: string | null;
  TicketNumber: number;
  NetWeight: string;
  Job: string;
  JobCompanyCode: string;
  ManualWeight: number;
  CostType: string;
  Version: number;
  OrderKey: number;
  OrderDescription: string;
  CustomerName: string;
  Address1: string;
  CustomerID: string;
  OrderProductCostType: string;
  OrderProductDescription: string;
  ProductID: string;
  ProductCategory: string;
  ProductKey: number;
  ProductDescription: string;
  isManual: number;
  notes: string;
  dateRef: string;
  FullName: string;
}


interface DriverItem {
  minutes: string;
  dateRef: string;
  isApproved: string;
}

interface DataItem {
  name: string;
  inspectionIn: number;
  inspectionOut: number | null;
  truckId: string;
  loads: Load[];
  driverId: string;
  DateRef: Date
}

interface MainContentProps {
  data: DataItem[];
}

interface ApproveResponse {
  success: boolean;
  message: string;
  affectedRows?: number;
}


function Stauts({ data }: MainContentProps) {

  const getMountainTimeDate = () => {
    const now = new Date(); // Gets the current date and time in the user's time zone
    const utc = Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), now.getUTCHours(), now.getUTCMinutes(), now.getUTCSeconds());
    const mountainTimeOffset = -6; // Mountain Daylight Time (MDT) offset from UTC
    return new Date(utc + mountainTimeOffset * 3600 * 1000); // Adjusts the date object by the offset
  
  };

  const [items, setItems] = useState<DataRecord[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [type, setType] = useState('');
  const [day, setDay] = useState('');
  const [searchTerm, setSearchTerm] = useState(''); // State to store search term
  const [statusTerm, setstatusTerm] = useState(''); // State to store search term
  const [dateTerm, setDateTerm] = useState(''); // State to store search term
  const [sumHours, setSumHours]=useState('') 
  const [drawerContent1, setDrawerContent1] = useState<Load1 | null>(null);
  const [drawerContent, setDrawerContent] = useState<DataItem | null>(null);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [contentType, setContentType] = useState<Number>(-1)
  const [driverItems, setDriverItems] = useState<DriverItem[]>([]);
  const [detailsItems, setDetailsItems] = useState<TicketData[]>([]);
  const [transDate, setTransDate] = useState(getMountainTimeDate());

  const handleCheckboxChange = (index:any) => {
   
    const newItems = filteredItems.map((item, idx) => {
      if (idx === index) {
        return { ...item, checked: !item.checked };
      }
      return item;
    });
    setItems(newItems);
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
     
      try {
       
        const response = await dashboardService.getStatusSum1({ type, day });
     
        const rawData = response.map((item: any) => ({ ...item, checked: false })); // Initialize checked state
        if(rawData.isValid==false){
          setItems([]);
        }
        
       
        setItems(rawData); // Convert the grouped object to an array
        const netValues = rawData.map((item: { Net: any; }) => item.Net);

        // Calculate the total hours from the 'net' values
        setSumHours(calculateTotalHours(netValues));
        setIsLoading(false);
      } catch (error) {

        console.error("Failed to fetch data:", error);
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleNameClick = async (item: DataItem) => {
   // alert(JSON.stringify(item));
   // alert(item.DateRef)
    setContentType(0);
    setDrawerVisible(true);
    let drId= item.truckId;

    setType(drId);
    setTransDate(item.DateRef);
    const response = await dashboardService.driverData1({ type, transDate });
    const rawData = response;
     setDetailsItems(rawData);
   // setDrawerContent(item);
  };

  const closeDrawer = () => {

    setContentType(-1);
    setDrawerVisible(false);
  }
  
  const formatDate = (dateString: string): string => {
    // Extract the date portion before the 'T' character
    const datePortion = dateString.split('T')[0];
    
    return datePortion;
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value); // Update search term as user types
  };

  const handleSearchChange1 = (e: React.ChangeEvent<HTMLInputElement>) => {
    setstatusTerm(e.target.value); // Update search term as user types
  };

  const handleSearchChange2 = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDateTerm(e.target.value); // Update search term as user types
  };

  let filteredItems: any[] = [];

// Check if the items array has elements before filtering
if (items.length > 0) {
    filteredItems = items.filter(item => 
        (item.DriverName || '').toLowerCase().includes(searchTerm.toLowerCase()) &&
        item.Status.includes(statusTerm) &&
        item.DateRef.includes(dateTerm)
    );
}

function getTime(printtime: string | null){
  // Parse the string into a Date object
  if(printtime== null || printtime==""){
    return "";
  }

  const date = new Date(printtime);

  // Get the hours and minutes in UTC
  const hours = date.getUTCHours();
  const minutes = date.getUTCMinutes();

  // Format the hours and minutes to ensure two digits
  const formattedHours = hours.toString().padStart(2, '0');
  const formattedMinutes = minutes.toString().padStart(2, '0');

  // Combine hours and minutes into a hh-mm format
  const time = `${formattedHours}:${formattedMinutes}`;

  return time;
}

const handelApprov = async () => {
   
  const response = await dashboardService.dayApprove({ type, day });
  const rawData = response; 
  if(rawData.isValid==true){
  alert("Approved. data updated!");
  } else{
  alert("Something went wrong please try again;")
  }
};

const handelReject = async () => {
 
  const response = await dashboardService.dayRejected({ type, day });
  const rawData = response; 
  if(rawData.isValid==true){
  alert("Rejected. data updated!");
  } else{
  alert("Something went wrong please try again;")
  }
};

const handledayClick = async (item: DriverItem) => {
   
  var sDate= formatDate(item.dateRef);
  setDay(sDate);
  const response = await dashboardService.driverDetails({ type, sDate });
  const rawData = response; // Adjust according to how you access the API response
  setDetailsItems(rawData);
};

  const totalHours = filteredItems.reduce((acc, item) => acc + (Number(item.minutes) || 0), 0);
  
  const exportToCsv = () => {
    const headers = "Driver Name,Hours,Status,Date\n";
    const rows = filteredItems.map(item => 
      `${item.DriverName},${item.minutes},${item.Status},${formatDate(item.DateRef)}`
    ).join("\n");

    const csvContent = headers + rows;
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", "export.csv");
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleToggleAll = () => {
    const filteredItems = getFilteredItems();
    const areAllFilteredChecked = filteredItems.every(item => item.checked);
  
    const newItems = items.map(item => {
      if (filteredItems.includes(item)) {
        return { ...item, checked: !areAllFilteredChecked };
      }
      return item;
    });
  
    setItems(newItems);
  };
  
  
  const getFilteredItems = () => {
    return items.filter(item => 
      (item.DriverName || '').toLowerCase().includes(searchTerm.toLowerCase()) &&
      item.Status.includes(statusTerm) &&
      item.DateRef.includes(dateTerm)
    );
  };
  

  async function AppoveAll() {
    const approvedItems = items.filter(item => item.checked);
    if(approvedItems.length==0){
      alert("Please select records")
      return;
    }

    if (window.confirm("Are you sure you want to Approve all?")) {
   
     
    for (const item of approvedItems) {
      try {
        // API call to approve the item with typed response
        const response = await dashboardService.approveItem({ truckId: item.truckId, date: item.DateRef, driverId: item.driverId });
        const data: ApproveResponse = response?.data;
  
        // Alert the response
    
        // Check if the update was successful
        if (data.success) {
          // Update the status of the current item if the update was successful
          item.Status = 'Approved';
        } else {
          // Optionally handle the case where the update was not successful
          console.error('Failed to approve item:', item);
        }
      } catch (error) {
        console.error('Error approving item:', item, error);
      }
    }
  
    // Update the state with the modified items array
    setItems([...items]);
  }else{

  }
  }

  async function RejectItem(item: any) {
   
    if (window.confirm("Are you sure you want to reject this item?")) {
      try {
        const response = await dashboardService.rejectItem({
          truckId: item.truckId, 
          date: item.DateRef, 
          driverId: item.driverId,
          Comment: item.Comment
        });
        const data: ApproveResponse = response?.data;
  
        if (data.success) {
          // Create a new array with updated items
          const updatedItems = items.map(it => {
            if (it.truckId === item.truckId && it.DateRef === item.DateRef && it.driverId === item.driverId) {
              return { ...it, Status: 'Rejected' }; // Ensure immutability
            }
            return it;
          });
          setItems(updatedItems);
        } else {
          console.error('Failed to update item:', item);
        }
      } catch (error) {
        console.error('Error in rejecting item:', item, error);
      }
    } else {
      // User clicked "Cancel", do not proceed with rejection
      console.log("Item rejection cancelled by user.");
    }
  }
  

  async function PendingAll() {
    const approvedItems = items.filter(item => item.checked);
   if(approvedItems.length==0){
      alert("Please select records")
      return;
    }

    if (window.confirm("Are you sure you want to Change status for all the selected Records?")) {
    for (const item of approvedItems) {
      try {
        // API call to approve the item with typed response
        const response = await dashboardService.pendingItem({ truckId: item.truckId, date: item.DateRef, driverId: item.driverId });
        const data: ApproveResponse = response?.data;
  
        if (data.success) {
          // Update the status of the current item if the update was successful
          item.Status = 'Pending';
        } else {
          console.error('Failed to approve item:', item);
        }
      } catch (error) {
        console.error('Error approving item:', item, error);
      }
    }

    setItems([...items]);
  }else{

  }
  }
  

  function calculateTotalHours(netArray:any) {
    let totalMinutes = 0;
    // Iterate through each net value in the array
    for (let net of netArray) {
        // Split the net value into hours and minutes
        let [hours, minutes] = net.split(':');
        
        // Convert hours and minutes to numbers
        hours = parseInt(hours);
        minutes = parseInt(minutes);

        // Convert hours and minutes to total minutes and add to totalMinutes
        totalMinutes += hours * 60 + minutes;
    }

    // Calculate total hours and remaining minutes
    const totalHours = Math.floor(totalMinutes / 60);
    const remainingMinutes = totalMinutes % 60;

    // Format the result as h:mm
    const result = `${totalHours}:${String(remainingMinutes).padStart(2, '0')}`;

    return result;
}

const handleStatusChange = (e:any) => {
  setstatusTerm(e.target.value);
};

const handleCommentChange = (index:number, newComment: string) => {
  setItems(currentItems => currentItems.map((item, idx) => {
      if (idx === index) {
          return {...item, Comment: newComment};
      }
      return item;
  }));
};

  return (
    <>
      <div>
        <div className="grid-container">
        <div>
          <label>
            <input
              type="radio"
              value="New"
              checked={statusTerm === 'New'}
              onChange={handleStatusChange}
            /> New
          </label>
          <label>
            <input
              type="radio"
              value="Pending"
              checked={statusTerm === 'Pending'}
              onChange={handleStatusChange}
            /> Pending
          </label>
          <label>
            <input
              type="radio"
              value="Approved"
              checked={statusTerm === 'Approved'}
              onChange={handleStatusChange}
            /> Approved
          </label>
          <label>
            <input
              type="radio"
              value="Rejected"
              checked={statusTerm === 'Rejected'}
              onChange={handleStatusChange}
            /> Rejected
          </label>
        </div>
        <div className="grid-container">
        
      </div>
        <input
          type="text"
          placeholder="Search by driver name..."
          value={searchTerm}
          onChange={handleSearchChange}
          className="search-input"
        />
       
        </div>
        <button onClick={exportToCsv} className="export-button">Export to CSV</button>
       
        <div>
          <div className="grid-container2">
          <p>Count:{filteredItems.length} Hours:{sumHours}</p>
          <p>Pending: {filteredItems.filter((item: { Status: string; }) => item.Status === 'Pending').length}</p>
          <p>Approved: {filteredItems.filter((item: { Status: string; }) => item.Status === 'Approved').length}</p>
          <p>Auto Approve: {filteredItems.filter((item: { Status: string; }) => item.Status === 'Auto Approve').length}</p>
          <p>Rejected: {filteredItems.filter((item: { Status: string; }) => item.Status === 'Rejected').length}</p>
          </div>
        </div>
        <div className="grid-container1">
        <button onClick={handleToggleAll} style={{width: 100}}>Select/Deselect All</button>
        <button onClick={AppoveAll} style={{width: 100}}>Approve All</button>
        <button onClick={PendingAll} style={{width: 100}}>Change to Pending </button>
        </div>
        <table className="table-style1">
          <thead>
            <tr>
            <th style={{width:50}}></th>
            <th style={{width:110}}>Date</th>
            <th style={{width:110}}>Truck ID</th>
             <th style={{width:110}}>Driver Id</th>
              <th style={{width:200}}>Driver Name</th>
              <th style={{width:50}}>Loads</th>
              <th style={{width:50}}>Hours</th>
              <th style={{width:80}}>Status</th>
              <th style={{width:50}}>PreTrip</th>
              <th style={{width:50}}>PostTrip</th>
              <th style={{width:50}}>Net</th>
              <th style={{width:400}}>Comments</th>
            
            </tr>
          </thead>
          <tbody>
            {filteredItems.map((item, itemIndex) => (
              <tr key={itemIndex}>
               <td><input
      type="checkbox"
      checked={item.checked}
      onChange={() => handleCheckboxChange(itemIndex)}
    /></td>

                 <td>{formatDate(item.DateRef)}</td>
                    <td>{item.truckId}</td>
                    <td onClick={() => handleNameClick(item)} >{item.driverId}</td>
                <td>{item.DriverName}</td>
                <td>{item.nonNullCount}</td>
                <td>{item.minutes}</td>
                <td style={{ color: item.Status === "Rejected" ? "red" : "black" }}>
                    {item.Status === "Pending" ? "Pending" : item.Status}
                </td>
                <td>{item.PreTrip}</td>
                <td>{item.PostTrip}</td>
                 <td>{item.Net}</td> 
                <td><input 
                type="text"
                style={{width:"90%", height:30}}
                value={item.Comment}
                onChange={(e) => handleCommentChange(itemIndex, e.target.value)}
            /></td>
                <td style={{width:20}}><button onClick={() => RejectItem(item)} style={{width: 55}}>Reject</button></td>
              </tr>
            ))}
          </tbody>
        </table>

        {drawerVisible && (
        <div className="drawer">
          {/* <p style={{marginLeft:20}}> Driver: {drawerContent?.name}</p> */}
          <button onClick={closeDrawer} style={{marginLeft:"80%"}}>Close</button>
          {contentType==0?
          <div>
           
         
                  <hr/>
       
                  <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}>
        {/* <button onClick={handelApprov} style={{height:30}}>Approve</button>
        <button onClick={handelReject} style={{height:30}}>Reject</button> */}
             
        </div>
       

        <h4>Details {day}</h4>
        <div className="table-container">
        <table className="table-style">
            <thead>
                <tr>
                    <th>Ticket#</th>
                    <th>TruckID</th>
                    <th>Phase#</th>
                    <th>PrintDateTime</th>
                    <th>Job</th>
                    <th>ProductID</th>
                    <th>OrderDescription</th>
                    <th>CustomerID</th>

                </tr>
            </thead>
            <tbody>
                { detailsItems && detailsItems.length>0 && detailsItems.map((item, index) => (
                    <tr key={index}>
                        <td>{item.TicketNumber}</td>
                        <td>{item.TruckID}</td>
                        <td>{item.Phase}</td>
                        <td>{getTime(item.PrintDateTime)}</td>
                        <td>{item.Job}</td>
                        <td>{item.ProductID}</td>
                        <td>{item.OrderDescription}</td>
                        <td>{item.CustomerID}</td>
                    </tr>
                ))}
            </tbody>
        </table>
        </div>
       
          </div>
         :<div style={{marginLeft:20}}>
            <h4>Load Details</h4>
            <p> Ticket# : {drawerContent1?.ticketNumber}</p>
            <p> Scale Time# : {drawerContent1?.printDateTime!= null?getTime(drawerContent1?.printDateTime):""}</p>
            <p> Job: {drawerContent1?.job}</p>
            <p> Customer : {drawerContent1?.customerName}</p>
            <p> Phase : {drawerContent1?.phase}</p>
            <p> Order Descr : {drawerContent1?.orderDescription}</p>
            <p> Product Desc: : {drawerContent1?.productDescription}</p>
          </div>}
        </div>
      )}
      </div>
    </>
  );
}

export default Stauts;
