import React, { useEffect, useState } from 'react';
import './compstyle.css'; 
import dashboardService from '../../apis/dashboardService'; 

interface Users {
  id: number;
  name: string;
  username: string;
  createdDate: Date;
  password: string;
  userId: string;  
}

interface MainContentProps {
  data: Users[];
}

function Users({ data }: MainContentProps) {
  const [users, setUsers] = useState<Users[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  
  const [newUser, setNewUser] = useState({
    name: '',
    username: '',
    userId: '',
    password: ''
  });

  useEffect(() => {
    fetchData();
  }, []);

  const deleteUser = async (userId:any) => {
    if (window.confirm('Are you sure you want to delete this user?')) {
      try {
        await dashboardService.deleteUser(userId); // Implement this method in your API service
        fetchData(); // Refresh the data
      } catch (error) {
        console.error('Failed to delete user:', error);
      }
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setNewUser(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const response = await dashboardService.getUsers();
      const rawData = response?.data;
      if (rawData && rawData.isValid === false) {
        setUsers([]);
      } else if (rawData) {
        setUsers(rawData);
      }
      setIsLoading(false);
    } catch (error) {
      console.error("Failed to fetch data:", error);
      setIsLoading(false);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsModalOpen(false);
    try {
      await dashboardService.addUser(newUser); // Assuming addUser is implemented in your service
      fetchData(); // Refresh list after adding
    } catch (error) {
      console.error('Failed to add user:', error);
    }
  };

  return (
    <>
      <button onClick={() => setIsModalOpen(true)}>Add User</button>
      {isModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <span className="close-button" onClick={() => setIsModalOpen(false)}>&times;</span>
            <form onSubmit={handleSubmit}>
              <label>Name: <input type="text" name="name" value={newUser.name} onChange={handleInputChange} /></label>
              <label>Username: <input type="text" name="username" value={newUser.username} onChange={handleInputChange} /></label>
              <label>User ID: <input type="text" name="userId" value={newUser.userId} onChange={handleInputChange} /></label>
              <label>Password: <input type="password" name="password" value={newUser.password} onChange={handleInputChange} /></label>
              <button type="submit">Submit</button>
            </form>
          </div>
        </div>
      )}
      <div className="user-list">
        {isLoading ? <p>Loading...</p> : (
          <table className="table-style1">
            <thead>
              <tr>
                <th>ID</th>
                <th>Name</th>
                <th>UserName</th>
                <th>UserId</th>
               </tr>
            </thead>
            <tbody>
              {users.map(user => (
                <tr key={user.id.toString()}>
                  <td>{user.id.toString()}</td>
                  <td>{user.name}</td>
                  <td>{user.username}</td>
                  <td>{user.userId}</td>
                 
                  <td>
                    <button onClick={() => deleteUser(user.id)}>Delete</button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </>
  );
}

export default Users;