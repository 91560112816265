import React from 'react';
import { createRoot } from 'react-dom/client'; 
import { BrowserRouter } from 'react-router-dom'; 
import Routes from './MyRoutes'; 
import { AuthProvider } from './AuthContext';
const root = createRoot(document.getElementById('root')!); 

root.render(
 
    <BrowserRouter>
    <AuthProvider>
      <Routes />
      </AuthProvider>
    </BrowserRouter>
  
);
